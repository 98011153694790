<template>
  <div>
    <h3>Материал</h3>
    <v-input v-model="form.name" label="Название"></v-input>
    <v-input v-model.number="form.sort" label="Сортировка"></v-input>
    <v-input v-model="form.bg" label="Фон"></v-input>
    <v-input v-model="form.icon" label="Иконка"></v-input>
    <v-input v-model="form.description" label="Описание"></v-input>

    <div class="mb-3 multi-input">
      <label class="form-label">{{ label }}</label>
      <div v-for="(item, idx) in form.texts" :key="idx" class="row">
        <div class="col">
          <label for="title">Title</label>
          <input id="title" :key="idx" class="form-control mb-2" v-model="item.title">
          <label for="text">Text</label>
          <textarea id="text" :key="idx" class="form-control mb-2" v-model="item.text"></textarea>
          <div class="fa-pull-right px-3 mb-2 btn btn-sm btn-danger" @click="form.texts.splice(idx, 1)">Удалить</div>
        </div>
      </div>
      <span class="btn btn-sm btn-primary" @click="form.texts.push({title: '', text: ''})">Добавить</span>
    </div>
    <div class="mb-3 multi-input">
      <label class="form-label">{{ label }}</label>
      <div v-for="(item, idx) in form.links" class="row" :key="idx">
        <div class="col">
          <input
              class="form-control mb-2"
              v-model="item.link"
          >
          <div class="fa-pull-right px-3 mb-2 btn btn-sm btn-danger" @click="selfValues.splice(idx, 1)">Удалить</div>
        </div>
      </div>
      <span class="btn btn-sm btn-primary" @click="form.links.push({link: ''})">Добавить</span>
    </div>
  </div>
</template>

<script>
import VInput from "@/components/basic/v-input";

export default {
  name: "FormArticle",
  components: {VInput},
  props: {
    form: Object,
  },
};
</script>

<style scoped></style>
