<template>
  <div v-if="articles" class="editor-article">
    <div class="editor__tools">
      <h2>Редактор материалов</h2>
      <button type="button" class="btn btn-primary btn-sm" @click="create">Добавить</button>
      <button v-if="!isSaved" type="button" class="btn btn-success btn-sm" @click="save">Сохранить</button>
    </div>
    <div class="editor row">
      <div class="col editor__list">
        <div
            v-for="(article) in articles"
            :key="article.id"
            :class="['editor__list-item', { '--active': article === currentArticle }]"
        >
          <span class="editor__list-item__link" @click="select(article.id)">
            {{article.sort}}
            <span class="editor__list-item__id">{{ article.id }}</span>
            {{ article.name }}
          </span>
          <div class="editor__list-item__action">
            <span @click="trash(article.id)"><i class="far fa-trash-alt"></i></span>
          </div>
        </div>
      </div>
      <div class="col editor__form" v-if="currentArticle">
        <form-article :form="currentArticle"></form-article>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.css";
import api from "@/api";
import FormArticle from "@/components/editor/form/form-article";

export default {
  name: "EditorArticlePage",
  components: {FormArticle},
  data: function () {
    return {
      articles: null,
      currentArticle: null,
      isSaved: true,
    };
  },
  watch: {
    currentArticle: {
      handler: function (val, oldVal) {
        if (!val) return false;
        if (oldVal === null) return false;
        if (oldVal.id !== val.id) return false;

        this.isSaved = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.$store.commit('setBackground', 'page')
    this.load();
  },
  methods: {
    load: async function () {
      this.articles = await api.loadArticles();
    },
    select: async function (id) {
      await this.save();
      this.currentArticle = this.articles.find(i => i.id === id);
    },
    create: async function () {
      await this.save();
      await api.createArticle();
      await this.load();
    },
    save: async function () {
      if (this.isSaved && this.currentArticle === null) return false;
      const status = await api.saveArticle(this.currentArticle.id, this.currentArticle);
      if (status === 200) this.isSaved = true;
    },
    trash: async function(id) {

      const confirm =  prompt('Для удаления напишите: DELETE')
      if (confirm !== "DELETE") return false;

      const status = await api.deleteArticle(id);
      if (status !== 200) return false;
      await this.load();

      if (this.currentArticle.id === id) this.currentArticle = null;
    },
  }
};
</script>

<style lang="sass">
body, html
  height: 100vh

.editor-article
  .editor
    height: 100vh

  .editor__form
    overflow-y: scroll
    padding-bottom: 150px
    height: 100%

    &__top-links
      background: #474646
      display: flex
      align-items: center
      height: 30px
      padding: 0 10px

      a
        display: inline-block
        color: #ffffff
        padding: 0 10px
        font-size: 13px

    &__tools
      padding: 10px
      background: #f2f2f2
      border-bottom: 1px solid #dbdbdb

    &__list
      background: #f2f2f2
      min-width: 200px
      max-width: 250px !important
      display: flex
      flex-direction: column
      align-items: center
      padding: 0
      border-right: 1px solid #dbdbdb

      &-item
        background: #fafafa
        width: 100%
        border-bottom: 1px solid #dbdbdb
        padding: 5px 15px 5px 25px
        display: flex
        justify-content: space-between

        &:hover
          background: #fff

          .far
            display: inline-block

        &.--active
          background: #c5f5e6

        .far
          color: #8d8d8d
          display: none
          margin-left: 5px

          &:hover
            color: #444
            cursor: pointer

        &__link:hover
          cursor: pointer

        &__id
          color: #0e67a7
          display: inline-block
          margin-right: 10px

        &__index
          color: #888
          font-size: 12px
          display: inline-block
          margin-right: 10px
  </style>
